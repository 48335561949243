<template>
    <div class="main-box">
        <div class="con-box">
            <div class="search-box">
                <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
                    <div class="search-con">
                        <div class="con-item">
                            <a-form-item label="信息类别">
                                <a-select v-model:value="xinxileibie" @change="onPickType" placeholder="请选择信息类别">
                                    <a-select-option value="news">资讯动态</a-select-option>
                                    <a-select-option value="policy">政策指引</a-select-option>
                                </a-select>
                            </a-form-item>
                        </div>
                        <div class="con-item">
                            <a-form-item label="信息标题">
                                <a-input-search v-model:value="xinxibiaoti" placeholder="请输入信息标题" />
                            </a-form-item>
                        </div>
                        <div class="con-itemB">
                            <a-button>确定筛选</a-button>
                        </div>
                    </div>
                </a-form>
            </div>
            <div class="content-box">
                <a-table :columns="columns" :data-source="lists" :loading="loading" :pagination="pagination" @change="onPage">
                    <template #operation="{record}">
                        <a-button type="primary" style="margin-right: 15px;" @click="$router.push({ path: '/InforManage/AddInfor', query: { id: record.INFORMATON_ID, type: 'edit' } })">编辑</a-button>
                        <a-popconfirm
                            title="确定要删除此条记录吗?"
                            ok-text="是"
                            cancel-text="否"
                            @confirm="onDel(record.INFORMATON_ID)"
                            @cancel="cancel"
                        >
                            <a-button type="primary">删除</a-button>
                        </a-popconfirm>
                    </template>
                </a-table>
            </div>
        </div>
    </div>
</template>

<script>
const columns = [
    {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
        align: 'center',
        width: 60
    },
    {
        title: '类别',
        dataIndex: 'leibie',
        key: 'leibie',
        align: 'center'
    },
    {
        title: '标题',
        dataIndex: 'biaoti',
        key: 'biaoti',
        align: 'center'
    },
    {
        title: '关键词',
        dataIndex: 'guanjianci',
        key: 'guanjianci',
        align: 'center'
    },
    {
        title: '发布时间',
        dataIndex: 'fabushijian',
        key: 'fabushijian',
        align: 'center'
    },
    {
        title: '操作',
        dataIndex: 'operation',
        align: 'center',
        slots: {
            customRender: 'operation'
        }
    }
]
export default {
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            columns,
            xinxileibie: 'news',
            xinxibiaoti: '',
            lists: [],
            page: 1,
            pagination: {},
            loading: false,
            zhengcedengji: ''
        }
    },
    methods: {
        // 删除信息
        onDel (id) {
            this.$store.dispatch('deleteInformation', { INFORMATON_ID: id, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('删除成功！')
                    if (this.xinxileibie === 'news') {
                        this.getNewsList()
                    } else {
                        this.getPolicyList()
                    }
                } else {
                    this.$message.error('系统异常，删除失败，请稍后重试！')
                }
            })
        },
        onPickType (e) {
            this.page = 1
            this.pagination.total = 0
            this.xinxileibie = e
            if (e === 'news') {
                this.getNewsList()
            } else {
                this.getPolicyList()
            }
        },
        // 翻页
        onPage (pagination) {
            this.page = pagination.current
            if (this.xinxileibie === 'news') {
              this.getNewsList()
            } else {
              this.getPolicyList()
            }
        },
        // 获取资讯动态
        getNewsList () {
            this.lists = []
            this.loading = true
            this.$store.dispatch('getNewsList', { showCount: 10, XCX_NAME:localStorage.getItem('xcxName'), currentPage: this.page, tm: new Date().getTime() }).then(res => {
                this.loading = false
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        this.pagination.total = res.page.totalResult
                        for (let i in datas) {
                            this.lists.push({
                                'key': parseInt(i) + 1 + ((this.page - 1) * 10),
                                'leibie': '资讯动态',
                                'biaoti': datas[i].INFOR_TITLE,
                                'guanjianci': datas[i].INFOR_KEY,
                                'fabushijian': datas[i].PROM_DATE,
                                'INFORMATON_ID': datas[i].INFORMATON_ID
                            })
                        }
                    } else {
                        this.lists = []
                        this.pagination.total = 0
                    }
                } else {
                    this.lists = []
                    this.pagination.total = 0
                    this.$message.error('系统异常，获取信息列表失败，请稍后重试！')
                }
            })
        },
        // 获取政策指引
        getPolicyList () {
            this.lists = []
            this.loading = true
            this.$store.dispatch('getPolicyList', { showCount: 10, XCX_NAME:localStorage.getItem('xcxName'), currentPage: this.page, POLICY_LEVEL: this.zhengcedengji, tm: new Date().getTime() }).then(res => {
                this.loading = false
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        this.pagination.total = res.page.totalResult
                        for (let i in datas) {
                            this.lists.push({
                                'key': parseInt(i) + 1 + ((this.page - 1) * 10),
                                'leibie': '政策指引',
                                'biaoti': datas[i].INFOR_TITLE,
                                'guanjianci': datas[i].INFOR_KEY,
                                'fabushijian': datas[i].PROM_DATE,
                                'INFORMATON_ID': datas[i].INFORMATON_ID
                            })
                        }
                    } else {
                        this.lists = []
                        this.pagination.total = 0
                    }
                } else {
                    this.lists = []
                    this.pagination.total = 0
                    this.$message.error('系统异常，获取信息列表失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        // 获取资讯动态
        this.getNewsList()
    }
}
</script>

<style lang="less" scoped>
.content-box ::v-deep(.ant-table-thead){
    background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */ 
    background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
    background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
}
.content-box ::v-deep(.ant-table-thead th){
    background-color: transparent;
    color: @color-ff;
}

</style>
<style lang="less" scoped>
.main-box{
    width: 100%;
    margin: 20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        border-radius: 5px;
        position: relative;
        .search-box{
            .search-con{
                display: flex;
                flex-wrap: wrap;
                .con-item{
                    width: 30%;
                }
                .con-itemC{
                    display: flex;
                    justify-content: space-between;
                    .ant-form-item{
                        width: 70%;
                    }
                    .con-itemB{
                        width: 30%;
                    }
                }
                .con-itemA{
                    width: 25%;
                }
                .con-itemB{
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    width: 15%;
                     ::v-deep(.ant-btn){
                        padding:0 32px;
                        color: #ffffff;
                        background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
                        background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */ 
                        background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
                        background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
                    }
                }
            }
        }
        .content-box{
            font-size: 16px;
        }
    }
}
</style>